// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS = {
  auth: '/auth',
  app: '/app'
};

export const PATH_HOME = {
  root: '/',
  cloud: 'https://www.sketch.com/s/0fa4699d-a3ff-4cd5-a3a7-d851eb7e17f0',
  purchase: 'https://material-ui.com/store/items/minimal-setting/',
  home: path(ROOTS.home, '/home/home')
};

export const PATH_AUTH = {
  root: ROOTS.auth,
  login: path(ROOTS.auth, '/login')
};

export const PATH_APP = {
  root: ROOTS.app,
  general: {
    root: path(ROOTS.app, '/dashboard'),
    dashboard: path(ROOTS.app, '/dashboard'),
    analytics: path(ROOTS.app, '/dashboard/analytics'),
    ecommerce: path(ROOTS.app, '/dashboard/ecommerce')
  },
  setting: {

  },
  warehouse: {
    root: path(ROOTS.app, '/warehouse'),
    import: path(ROOTS.app, '/warehouse/import'),
    search: path(ROOTS.app, '/warehouse/search')
  },  
  app: {
    root: path(ROOTS.app, '/setting'),
    tracking: path(ROOTS.app, '/tracking'),    
    filemanager: path(ROOTS.app, '/filemanager'),
    profile: path(ROOTS.app, '/profile'),
    filemanagercsv: path(ROOTS.app, '/filemanager/csv/:form'),
    filemanagerphp: path(ROOTS.app, '/filemanager/php/:form'),
    importstorelocator: path(ROOTS.app, '/importexport/storelocator'),
    listing: path(ROOTS.app, '/listing/:list'),
    importexport: path(ROOTS.app, '/importexport/:list'),
    formssetting: path(ROOTS.app, '/forms/setting'),
    formssettingauth: path(ROOTS.app, '/forms/setting/auth'),
    formssettingsmtp: path(ROOTS.app, '/forms/setting/smtp'),
    formssettingcsv: path(ROOTS.app, '/forms/setting/csv'),
    formssettingmailchimp: path(ROOTS.app, '/forms/setting/mailchimp'),
  },
  visualblock: {
    post: path(ROOTS.app, '/post/:entity/single/:id/visualblock/:visual'),
  },  
  // productitem: {
  //   post: path(ROOTS.app, '/post/:entity/single/:id/item/:visual'),
  // },  
  item: {
    post: path(ROOTS.app, '/post/:entity/single/:id/item/:visual'),
  },  
  formssettingmailchimp: {
    post: path(ROOTS.app, '/forms/setting/mailchimp/single/:id'),
  },
  single: {
    post: path(ROOTS.app, '/post/:entity/single/:id'),
  },
  caching: path(ROOTS.app, '/caching'),
};
